import axios from '@/configs/http/internal-api-axios'

function getOptionLineChartDBCustomer() {
  return {
    chart: {
      height: 320,
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    xaxis: {
      categories: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
    },
    title: {
      text: 'アカウント',
    },
    colors: ['#556ee6'],
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return Number(value)
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number(value) < 1 || value === Number.POSITIVE_INFINITY ? '' : value
        },
      },
    },
  }
}

function getOptionColumnChart() {
  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '15%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
    },
    title: {
      text: '円',
    },
    colors: ['#556EE6'],
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return Number(value)
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number(value) < 1 || value === Number.POSITIVE_INFINITY ? '' : Math.round(value)
        },
      },
    },
  }
}

function getOptionLineChartDB() {
  return {
    chart: {
      height: 320,
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    xaxis: {
      categories: [
        '11月',
        '12月',
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
      ],
    },
    title: {
      text: '社',
    },
    colors: ['#556ee6'],
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return Number(value)
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number(value) < 1 ? '' : value
        },
      },
    },
  }
}

async function getListApps() {
  const response = await axios.get('/api/v1/mt/dashboard/apps')
  if (response.data) {
    return response.data.data
  }
}
async function getTotalUserActive() {
  const response = await axios.get('/api/v1/mt/dashboard/user_active_app')
  if (response.data) {
    return response.data.data
  }
}
async function getTotalUserInactive() {
  const response = await axios.get('/api/v1/mt/dashboard/user_inactive_app')
  if (response.data) {
    return response.data.data
  }
}
async function getTotalAppActive() {
  const response = await axios.get('/api/v1/mt/dashboard/app_active')
  if (response.data) {
    return response.data.data
  }
}

async function getCareerChart(id) {
  const response = await axios.get('/api/v1/mt/dashboard/saas_career?type=' + id)
  if (response.data) {
    return response.data.data.records
  }
}

async function getTotalCareer(id) {
  const response = await axios.get('/api/v1/mt/dashboard/saas_career?type=' + id)
  if (response.data) {
    return response.data.data.total
  }
}

async function getListCareer() {
  const response = await axios.get('/api/v1/setting/careers')
  if (response.data) {
    return response.data.data
  }
}

async function getSaasChart(id) {
  const response = await axios.get('/api/v1/mt/dashboard/career_saas?type=' + id)
  if (response.data) {
    return response.data.data
  }
}

async function getStackChart(type, year, career) {
  const response = await axios.get(
    '/api/v1/mt/dashboard/sales?type=' + type + '&year=' + year + '&career=' + career
  )
  if (response.data) {
    return response.data.data
  }
}
export const dashboardService = {
  getOptionLineChartDBCustomer,
  getOptionColumnChart,
  getOptionLineChartDB,
  getListApps,
  getTotalUserActive,
  getTotalUserInactive,
  getTotalAppActive,
  getCareerChart,
  getListCareer,
  getTotalCareer,
  getSaasChart,
  getStackChart,
}
